/**
 * 财务中心
 * */
 export default [
    {
        path: '/financial/financialOverview',
        component: resolve => require(['../../components/pages/financial/financialOverview.vue'], resolve),
        meta: {title: '资产概览'}
    },
    {
        path: '/financial/financialBalance/balanceInfo',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceInfo.vue'], resolve),
        meta: {title: '余额资产概览'}
    },
    {
        path: '/financial/financialBalance/balanceWater',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWater.vue'], resolve),
        meta: {title: '商户余额流水'}
    },
    {
        path: '/financial/financialBalance/balanceWaterUser',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWaterUser.vue'], resolve),
        meta: {title: '用户余额流水'}
    },
    {
        path: '/financial/financialBalance/balanceWithdrawal',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWithdrawal.vue'], resolve),
        meta: {title: '用户提现流水'}
    },
    {
        path: '/financial/financialBalance/balanceWithdrawalInfo',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWithdrawalInfo.vue'], resolve),
        meta: {title: '提现流水详情'}
    },
    {
        path: '/financial/financialBalance/balanceWaterAll',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWaterAll.vue'], resolve),
        meta: {title: '微信总进出汇总'}
    },
    {
        path: '/financial/financialBalance/balanceWaterAllUser',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceWaterAllUser.vue'], resolve),
        meta: {title: '用户总进出汇总'}
    },
    {
        path: '/financial/financialBalance/balanceOperateLog',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceOperateLog.vue'], resolve),
        meta: {title: '余额明细'}
    },
    // {
    //     path: '/financial/financialBalance/balanceOperateInfo',
    //     component: resolve => require(['../../components/pages/financial/financialBalance/balanceOperateInfo.vue'], resolve),
    //     meta: {title: '余额操作详情'}
    // },
    {
        path: '/financial/financialBalance/balanceLendLog',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceLendLog.vue'], resolve),
        meta: {title: '借出明细'}
    },
    {
        path: '/financial/financialBalance/balanceBorrowLog',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceBorrowLog.vue'], resolve),
        meta: {title: '借入明细'}
    },
    {
        path: '/financial/financialBalance/balanceApproveLog',
        component: resolve => require(['../../components/pages/financial/financialBalance/balanceApproveLog.vue'], resolve),
        meta: {title: '资金操作'}
    },
    {
        path: '/financial/wxpayAccount',
        component: resolve => require(['../../components/pages/asset/WxpayAccount.vue'], resolve),
        meta: {title: '微信支付商户号配置'}
    },
    {
        path: '/financial/WechatAuth',
        component: resolve => require(['../../components/pages/asset/WechatAuth.vue'], resolve),
        meta: {title: '微信公众号用户授权'}
    },
    {
        path: '/financial/SubscribeConfig',
        component: resolve => require(['../../components/pages/asset/SubscribeConfig.vue'], resolve),
        meta: {title: '微信订阅消息配置'}
    },
    {
        path: '/financial/financialInterface',
        component: resolve => require(['../../components/pages/financial/financialInterface.vue'], resolve),
        meta: {title: '接口管理'}
    },
]